



























































































import { ref as _ref, computed as _computed } from "@vue/composition-api";
import { Form, Message } from "element-ui";
import CampaignDefaults from "@/components/CampaignDefaults/index.vue";
import { CountrySelector, PublisherSelector } from "@/components/selectors";
import router from "@/router";
import { lookupUsers, Lookup } from "@/select";
import PricingModelTable from "./components/PricingModelTable.vue";
import Agencies, { defaultAgencyData } from "@/select2/agency";
import request, { toHtml } from "@/select2/api/base";
import { asCurrency, asPercentage } from "@/utils";
import { nonemptyArray, requiredNumber, requiredText } from "@/rules";
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const rules = {
    name: [requiredText],
    display_adserving_fee: [requiredNumber],
    video_adserving_fee: [requiredNumber],
    countries: [nonemptyArray],
    publishers: [nonemptyArray]
  };

  let agency: any = _ref({ ...defaultAgencyData
  });

  let contacts: Lookup[] = _ref([]);

  let form: InstanceType<typeof Form> = _ref(null);

  let pmTable: InstanceType<typeof PricingModelTable> = _ref(null);

  let submitting = _ref(false);

  const isAdmin = User.isAdmin;
  const routeId = Number(router.currentRoute.params.id);
  const isEdit = !isNaN(routeId);
  onMounted(async () => {
    const params: {
      agency?: number;
    } = {};

    if (isEdit) {
      params.agency = routeId;
      pmTable.value?.init(routeId);
      agency.value = await Agencies.get(routeId);
    } else if (!isAdmin) {
      pmTable.value?.init();
      agency.value = await request.get("agency");
    }

    const response = await lookupUsers(params);
    contacts.value = response.data;
  });

  async function submit() {
    submitting.value = true;
    const body = { ...agency.value,
      pricing_models: pmTable.value.getPricingModels().map(p => ({ ...omit(["hasDefault"], p),
        value: p.hasDefault ? p.value : null
      }))
    };

    try {
      if (isAdmin) {
        if (!isEdit) {
          await Agencies.create(body);
        } else {
          await Agencies.update(routeId, body);
        }

        Message.success(`Saved '${agency.value.name}`);
        router.push("/agencies/");
      } else {
        await request.put("agency/", body);
        Message.success(`Account settings updated`);
      }
    } catch (e) {
      Message({
        type: "error",
        message: (toHtml((e as any)) as any)
      });
    } finally {
      submitting.value = false;
    }
  }

  function handleSave() {
    form.value.validate(async valid => {
      if (valid) {
        submit();
      }
    });
  }

  function fee(val: number, type: 1 | 2): string {
    if (type === 1) return asCurrency(val);
    if (type === 2) return asPercentage(val);
    return val.toLocaleString();
  }

  let displayFee = _computed(() => {
    return fee(agency.value.display_adserving_fee, agency.value.display_fee_type);
  });

  let videoFee = _computed(() => {
    return fee(agency.value.video_adserving_fee, agency.value.video_fee_type);
  });

  return {
    rules,
    agency,
    contacts,
    form,
    pmTable,
    isAdmin,
    handleSave,
    displayFee,
    videoFee
  };
};

__sfc_main.components = Object.assign({
  CampaignDefaults,
  CountrySelector,
  PublisherSelector,
  PricingModelTable
}, __sfc_main.components);
export default __sfc_main;
