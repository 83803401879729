





















































import ActiveDaysField from "@/components/ActiveDays/index.vue";
import { FrequencyCapDuration, LineItemFrequencyCapDuration } from "@/select";
import { ICampaign } from "@/select/types";
const __sfc_main = {};
__sfc_main.props = {
  campaign: null
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  function handleChange() {
    emit("change", props.campaign);
  }

  const hourOptions = {
    start: "00:00",
    step: "01:00",
    end: "23:00"
  };
  return {
    FrequencyCapDuration,
    LineItemFrequencyCapDuration,
    props,
    handleChange,
    hourOptions
  };
};

__sfc_main.components = Object.assign({
  ActiveDaysField
}, __sfc_main.components);
export default __sfc_main;
