

































import { ref as _ref } from "@vue/composition-api";
import DecimalInput from "@/components/DecimalInput/index.vue";
import { pmDisplay } from "@/select";
import PricingModels, { defaultPricingModelData, PricingModel } from "@/select2/pricingmodel";
interface PricingModelUI extends PricingModel {
  hasDefault: boolean;
}
const __sfc_main = {};
__sfc_main.props = {
  fixedActive: {
    key: "fixedActive",
    required: false,
    type: Boolean,
    default: false
  },
  percentageActive: {
    key: "percentageActive",
    required: false,
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  let initialised = _ref(false);

  let pricingModels: PricingModelUI[] = _ref([{ ...defaultPricingModelData,
    hasDefault: false
  }]);

  async function init(agencyId?: number) {
    const params: {
      agency?: string;
    } = {};
    if (agencyId) params.agency = agencyId.toString();
    const response = await PricingModels.list(params);
    pricingModels.value = response.results.map(p => ({ ...omit(["agency"], p),
      hasDefault: p.value !== null
    }));
    initialised.value = true;
  }

  function getPricingModels() {
    return [...pricingModels.value];
  }

  const props = __props;

  function add() {
    pricingModels.value.push({ ...defaultPricingModelData,
      hasDefault: false
    });
  }

  function deletePricingModel(i: number) {
    pricingModels.value.splice(i, 1);
  }

  return Object.assign({
    pmDisplay,
    pricingModels,
    props,
    add,
    deletePricingModel
  }, {
    init,
    getPricingModels
  });
};

__sfc_main.components = Object.assign({
  DecimalInput
}, __sfc_main.components);
export default __sfc_main;
