

















import { computed as _computed } from "@vue/composition-api";
import days from "@/utils/days.json";
const __sfc_main = {};
__sfc_main.props = {
  value: {
    key: "value",
    required: true,
    type: Array
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  function handleChange(id) {
    const add = props.value.includes(id);
    const newValue = add ? props.value.filter(v => v !== id) : [id, ...props.value].sort();
    emit("input", newValue);
  }

  function toggleAll(val) {
    emit("input", !val ? [] : days.map(d => d.id));
  }

  let allTicked = _computed(() => props.value.length === days.length);

  let isIndeterminate = _computed(() => props.value.length > 0 && props.value.length < days.length);

  return {
    days,
    handleChange,
    toggleAll,
    allTicked,
    isIndeterminate
  };
};

export default __sfc_main;
