import Generate from "./api/crud"

export interface PricingModel {
	id?: number
	name: string
	type: number | null
	value: number | null
}

export interface PricingModelForm extends Omit<PricingModel, "id"> {}
export interface PricingModelData extends PricingModel {
	id: number
	type: number
}

export const defaultPricingModelData: PricingModel = {
	name: "",
	type: null,
	value: null,
}

export default Generate<PricingModelForm, PricingModelData>("/pricingmodels")
