















import { ref as _ref } from "@vue/composition-api";
import { asCurrency, asPercentage } from "@/utils";
const __sfc_main = {
  inheritAttrs: false
};
__sfc_main.props = {
  size: {
    key: "size",
    required: false,
    type: String,
    default: "medium"
  },
  disabled: {
    key: "disabled",
    required: false,
    type: Boolean,
    default: false
  },
  value: {
    key: "value",
    required: false,
    type: [Number, null],
    default: 0
  },
  precision: {
    key: "precision",
    required: true,
    type: Number
  },
  display: {
    key: "display",
    required: false,
    type: String,
    default: ""
  },
  blank: {
    key: "blank",
    required: false,
    type: Boolean,
    default: false
  }
};

__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;

  let displayValue = _ref("");

  onMounted(() => {
    displayValue.value = !props.value && props.value !== 0 ? "" : displayFunc(props.value);
  });

  function handleChange(val) {
    displayValue.value = displayFunc(val);
    emit("change", val);
  }

  watch(() => props.value, newVal => {
    displayValue.value = displayFunc(newVal);
  });

  function handleFocus() {
    getValue();
  }

  function getValue() {
    if (props.value !== null) displayValue.value = props.value.toFixed(props.precision);else displayValue.value = "";
  }

  function handleBlur() {
    if (displayValue.value === "") {
      emit("input", null);
      return;
    }

    const newValue = Number(parseFloat(displayValue.value).toFixed(props.precision));

    if (!isNaN(newValue)) {
      emit("input", newValue);
      displayValue.value = displayFunc(newValue);
    } else {
      displayValue.value = displayFunc(props.value);
    }
  }

  function displayFunc(val) {
    if (props.display === "currency") return asCurrency(val);
    if (props.display === "percentage") return asPercentage(val);
    return val.toString();
  }

  return {
    displayValue,
    handleChange,
    handleFocus,
    handleBlur
  };
};

export default __sfc_main;
