export type Rules = { [key: string]: Object[] }

export const requiredText = {
	type: "string",
	required: true,
	message: "Field is required",
	trigger: "blur",
}

export const requiredNumber = {
	type: "number",
	required: true,
	message: "Field is required",
	trigger: "blur",
}

export const requiredObject = {
	type: "object",
	required: true,
	message: "Field is required",
	trigger: "blur",
}

export const nonemptyArray = {
	type: "array",
	required: true,
	message: "At least one option must be selected",
	trigger: "blur",
}

export const requiredUrl = {
	type: "url",
	required: true,
	message: "Must be a valid URL",
	trigger: "blur",
}
